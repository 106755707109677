.signup-and-login-form {
  .signup-and-login-form-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: 24px;

    .header-label-container {
      margin-top: 12px;
    }

    .form-control {
      width: 100%;
      text-align: center;

      & + .form-control {
        margin-top: 16px;
      }
    }

    .login-or-signup-buttons-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: baseline;
      margin-top: 24px;
      margin-bottom: 24px;

      button {
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;

        border: 2px solid;
      }

      .back-to-email-input-link {
        width: calc(40% - 48px);

        color: #0e9ecd;
      }
    }
  }
}
