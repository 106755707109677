.router-error-page {
  .error-page-body {
    padding: 12px;

    .error-page-header {
      padding: 24px 12px;

      .emoticon {
        font-size: 24px;
        font-weight: bold;

        padding-right: 36px;
      }

      .message {
        font-size: 24px;
      }
    }

    .error-message-container {
      padding: 10px;
      margin-top: 12px;
      margin-bottom: 24px;
      outline: 2px dashed gray;
    }
  }
}
