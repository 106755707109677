.map-container {
  width: 100%;
  height: 500px;

  outline: 1px dashed red;

  .homepage-google-map {
    width: 100%;
    height: 100%;
  }
}
