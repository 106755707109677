#google-map-container {
  .gm-style-cc {
    // Hide the "Keyboard shortcuts" button.
    &:has(button[aria-label="Keyboard shortcuts"]),
    // Hide the "Report a map error" link.
    &:has(a[title="Report errors in the road map or imagery to Google"]) {
      display: none;
    }
  }
}
