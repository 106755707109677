.nav-header-profile-box {
}

.nav-header-profile-box-drawer {
  .drawer-content {
    margin: 12px;

    .drawer-header {
      text-align: right;

      // .drawer-close-button {
      //   margin-top: 12px;
      //   margin-right: 12px;
      // }
    }

    .drawer-content-body {
      opacity: 1;

      &.is-hidden {
        opacity: 0;
      }
    }
  }
}
