.header {
  display: block;
  // JRG: TEST
  outline: 2px dashed blue;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-height: 40px;

  & > .left-side {
  }

  & > .right-side {
    & > .header-profile-box {
      align-self: flex-end;
      outline: 2px dashed red;
    }
  }
}
